.App {
    text-align: center;
}

.App a:not(.Title-link) {
    color: darkred;
    background-color: aquamarine;
    padding: 2px 4px;
    text-decoration: none;
}

.App .Title-link {
    text-decoration: none
}

.App a:hover {
    background-color: blue;
}

.Title {
    background-color: #282c34;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    margin-block-end: 0em;
    flex-direction: row;
}

.Title-link {
    background-color: #282c34;
}

.Title-logo {
    /*background-color: red;*/
}

.Title-word {
    /*background-color: green;*/
}

.Title-definition {
    text-align: left;
    padding-left: 20px;
    margin-right: 20px;
    /*background-color: blue;*/
}

.Title-logo img {
    scale: 0.3;
    margin: -180px;
}

.Title h1 {
    margin-block-start: 0.2em;
    margin-block-end: 0em;
    font-size: calc(10px + 8vmin);
}

.Title h2 {
    margin-block-start: 0em;
    margin-block-end: 0.3em;
    font-size: calc(8px + 3vmin);
}

.Title p {
    font-size: calc(8px + 1vmin);
}

.Nav-menu {
    margin-block-start: 0em;
    background-color: #61dafb;
}

.Nav-list {
    margin-block-start: 0em;
}

.Nav-list li {
    display: inline-block;
    list-style-type: none;
    padding: 10px;
    border-right: 1px #282c34;
}

.Main-content {
    text-align: left;
    margin: 0.5em;
}

.Beers {
    text-align: left;
    border-color: #282c34;
    border-style: solid;
    border-radius: 8px;
    margin-left: 5px;
    margin-right: 5px;
    background-color: burlywood;
}

.Beers h3 {
    margin-bottom: -10px;
    text-indent: 10px;
    margin-top: 0;
    border-bottom-color: #282c34;
    border-bottom-style: solid;
    padding-bottom: 5px;
    padding-top: 5px;
    background-color: lightgray;
}

.Beers-list {
    list-style-type: none;
}

.Beers-list li {
    border-style: solid;
    border-radius: 4px;
    background-color: lightgoldenrodyellow;
    padding: 5px;
    margin: 5px 5px 5px -35px;
    flex-direction: row;
}

.DeleteButton {
    align-self: end;
    margin-left: 10px;
}

.Form {
    flex-direction: column;
}

.FormRow {
    flex-direction: row;
}